import Auth from '@aws-amplify/auth';
import axios from 'axios';
export default {
    methods: {
        confirmMixinAvailable () {
            console.log('mixins are available')
        },
        recursiveValues (obj) {
            // recursively generate a list of an objects values

            var res = [];
            for (var key in obj){
                var val = obj[key];
                if (typeof(val) === 'object') {
                    res = res.concat(this.recursiveValues(val))
                } else {
                    res.push(val);
                }
            }
            return res;
        },
        dotPathObject (obj) {
            var res = {};
            for (var key in obj){
                var val = obj[key]
                if (typeof(val) === 'object' && !(Array.isArray(val))){
                    var subres = this.dotPathObject(val);
                    for (var subkey in subres) {
                        res[[key, subkey].join('.')] = subres[subkey];
                    }
                } else {
                    res[key] = val;
                }
            }
            return res;
        },
        deepMerge (target, source) {

            // start with new obj as target
            let newObj = {}
            Object.assign(newObj, target)

            // update newObj with source
            for (let key in source) {

                let bothTruthy = (newObj[key] && source[key])
                let bothObjects = (typeof (newObj[key]) === 'object' && typeof(source[key]) === 'object')

                // if both truthy objects, recurse
                if (bothTruthy && bothObjects) {
                    newObj[key] = this.deepMerge(newObj[key], source[key])

                // in any other case, just assign
                } else {
                    newObj[key] = source[key]
                }
            }

            return newObj
        },
        getErrorString(error){

            // we get a network error when API has 
            // internal server error; for other errors
            // like bad request, we respond with a message.
            // use this method to get the string to display
            if (!error.response){
                return "Internal server error"
            } else if (error.response.data) {
                if (error.response.data.message){
                    return error.response.data.message
                } else {
                    return JSON.stringify(error.response.data)
                }
            } else {
                return JSON.stringify(error.response)
            }
        },
        addDefaultAccountValues (account) {
            // add default values to account object for vue app
            account._o2_type = 'account';
            if (!account.connectria) {
                account.connectria = { shortname: null }
            }
            if (!account.aws) {
                account.aws = {alias: null}
            }
            return account
        },
        async automationApiRequest(endpoint, method, data={}) {
            // request any automation.aws.connectria.com endpoint

            // get token
            const tok = await Auth.currentSession().then(data => data.getAccessToken().getJwtToken())

            // construct url
            const accountUrl = process.env.VUE_APP_AUTOMATION_API_URL + endpoint;

            console.log("Requesting url: " + accountUrl)

            var result = null;

            // make request
            await axios({

                method: method,
                url: accountUrl,
                data: data,
                headers: { Authorization: 'Bearer ' + tok }

            }).then(response => {
                result = response.data;
            }).catch(
                err => {
                    console.log("Request failed " + endpoint + " " + err)
                    throw(err)
                }
            );

            return result
        },
        urlEncodeObj(queryObj) {
            let queryItems = [];
            for (let key in queryObj) {
                queryItems.push(key + "=" + encodeURIComponent(queryObj[key]));
            }
            return queryItems.join('&');
        },
        async *paginateAutomationApi(endpoint, queryObj={}) {
            
            let morePages = true;
            let nextToken = null;
            let responseData = {};

            while (morePages) {
                
                if (nextToken) {
                    queryObj.next = nextToken;
                }
                
                let queryString = this.urlEncodeObj(queryObj);
                if (queryString) {
                    queryString = "?" + queryString;
                }
                await this.automationApiRequest(endpoint + queryString, "GET").then(res => {
                    responseData = res
                });

                nextToken = responseData.NextToken;
                morePages = responseData.NextToken;

                yield responseData;

            }
        },
        async fullAutomationApiResults(endpoint, queryObj={}){

            let items = [];
            for await (const page of this.paginateAutomationApi(endpoint, queryObj)){
                items = items.concat(page.Items);
            }
            return items

        },
        addDefaultResourceValues(resource) {
            resource._o2_type = 'resource';
            return resource;
        },
        navigateToItem(item) {
            if (item._o2_type === 'account') {
                this.$router.push({ path: `/accounts/${item.accountid}` })
            } else if (item._o2_type === 'resource') {
                this.$router.push({ path: `/resources/${item.id}` })
            } else if (item._o2_type === 'tag') {
                this.$router.push({ path: `/tags/${item.id}` })
            } else if (item._o2_type === 'gadget') {
                this.$router.push({ path: `/gadget/${item.id}` })
            } else {
                this.$router.push({ path: `/automations/${item.id}` })
            }
            this.$router.go(1)
        },
        getAwsRegions() {
            return [
                "us-east-1",
                "us-east-2",
                "us-west-1",
                "us-west-2",
                "eu-north-1",
                "eu-central-1",
                "eu-west-3",
                "eu-west-2",
                "eu-west-1",
                "sa-east-1",
                "ca-central-1",
                "ap-northeast-2",
                "ap-northeast-1",
                "ap-south-1",
                "ap-southeast-1",
                "ap-southeast-2"
            ]
        },
        getAzureRegions() {
            const allRegions =  [
                "eastasia",
                "southeastasia",
                "centralus",
                "eastus",
                "eastus2",
                "westus",
                "northcentralus",
                "southcentralus",
                "northeurope",
                "japanwest",
                "japaneast",
                "brazilsouth",
                "australiaeast",
                "australiasoutheast",
                "southindia",
                "centralindia",
                "westindia",
                "jioindiawest",
                "jioindiacentral",
                "canadacentral",
                "canadaeast",
                "uksouth",
                "ukwest",
                "westcentralus",
                "westus2",
                "koreacentral",
                "koreasouth",
                "francecentral",
                "francesouth",
                "australiacentral",
                "australiacentral2",
                "uaecentral",
                "uaenorth",
                "southafricanorth",
                "southafricawest",
                "switzerlandnorth",
                "switzerlandwest",
                "germanynorth",
                "germanywestcentral",
                "norwaywest",
                "norwayeast",
                "brazilsoutheast",
                "westus3",
                "swedencentral"
            ]
        allRegions.sort()
        return allRegions.filter(s=>
            (s.includes('us') && !s.includes('australia'))
        ).concat(allRegions.filter(s=>
            !(s.includes('us') && !s.includes('australia'))
        ))
        }
    }
}
