<template>
  <div>
    <div class="terminal-container">
      <div id="terminal"></div>
    </div>
    <h4 style="margin-top: 1rem; text-align: center;">
      <b-button variant="danger" @click="cleanUp">Close Session <b-icon icon="x-circle" /></b-button>
    </h4>
  </div>
</template>

<script>
// see https://github.com/bertrandmartel/aws-ssm-session
import { ssm } from '@/ssm-session'
import { Terminal } from "xterm";
import "xterm/css/xterm.css";
import mixins from '@/mixins';

const termOptions = {
  fontFamily: "Fira Code, courier-new, courier, monospace",
};

export default {
  name: 'SsmSessionTerminal',
  components: {},
  mixins: [mixins],
  props: {
    webSocketUrl: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    },
    sessionId: {
      type: String,
      default: ""
    },
    terminateSessionEndpoint: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      sequenceNumber: 0,
      socket: null,
      terminal: null,
      termOptions: termOptions,
      textDecoder: null,
      textEncoder: null
    }
  },
  mounted () { 

    this.socket = new WebSocket(this.webSocketUrl)
    this.terminal = new Terminal(termOptions)
    this.textDecoder = new TextDecoder()
    this.textEncoder = new TextEncoder()
    
    // mount terminal to specified div
    this.terminal.open(document.getElementById('terminal'));

    // setup socket
    this.socket.binaryType = 'arraybuffer';
    this.socket.addEventListener("open", () => {
      ssm.init(this.socket, {
        token: this.token,
        termOptions: termOptions,
      });
    });

    // log when websocket closed
    this.socket.addEventListener("close", () => {
      console.log("Websocket closed");
    });

    // when we receive a message from the socket, write it to the terminal
    this.socket.addEventListener("message", (event) =>{
      var agentMessage = ssm.decode(event.data);
      ssm.sendACK(this.socket, agentMessage);
      if (agentMessage.payloadType === 1) {
        this.terminal.write(this.textDecoder.decode(agentMessage.payload));
      } else if (agentMessage.payloadType === 17) {
        ssm.sendInitMessage(this.socket, termOptions);
      }
    });

    // when the user puts data in the terminal, send it through the socket
    this.terminal.onData((data) => {
      ssm.sendText(this.socket, this.textEncoder.encode(data), this.sequenceNumber);
      this.sequenceNumber++;
    });
    this.terminal.focus();
    
  },
  beforeDestroy () {
    this.cleanUp()
  },
  methods : {
    cleanUp () {
      this.socket.close()
      this.terminal.dispose()
      this.automationApiRequest(
        this.terminateSessionEndpoint,
        'post',
        {SessionId: this.sessionId}
      )
    }
  }
}
</script>



<style scoped>
.terminal-container {
  padding: 2rem;
  background-color: black;
  border-radius: 1rem;
  max-width: 80rem;
  margin: 0 auto;
}


</style>