<template>
    <div style="padding: 0 2rem;">
        <b-tabs lazy align="center" content-class="mt-3">
            <b-tab title="Info" style="margin: 0 auto; max-width: 50rem" active>

                <!-- spinner while running -->
                <div v-if="!jobCompleted" style="margin: 0.5rem 3rem;">
                    <b-spinner variant="warning" label="Loading..."></b-spinner> Job is running
                </div>

                <!-- loader -->
                <div v-if="loadingGadget" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading Job info...
                </div>
                
                <ObjectInfo :key="lastUpdated" v-else :obj="gadgetJob" />

            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
export default {
  name: 'gadget',
  mixins: [mixins],
  components: {
      ObjectInfo
  },
  data() {
    return {
        jobid: this.$route.params.jobId,
        gadget: null,
        gadgetJob: {},
        lastupdated: "",
        reloadableStatus: ['processing', 'received', 'initialized'],
        loadingGadget: true,
        jobCompleted: true,
        gadgetFields: [
            {
                "key" : "status",
                "sortable" : true
            },
            {
                "key" : "id",
                "sortable" : true
            },
            {
                "key" : "command",
                "sortable" : true
            },
            {
                "key" : "date_created",
                "sortable" : true
            },
            {
                "key" : "init_result.exit_code",
                "sortable" : true
            },
            {
                "key" : "command_result.exit_code",
                "sortable" : true
            },
        ],
    }
  },
  created () {
    this.timer = setInterval(this.refreshJob, 3000)
  },
  methods: {
      refreshJob(){
        this.automationApiRequest(
            '/gadget/jobs/' + this.jobid, 'get'
            
        ).then(data => {
            console.log(data)
            this.gadgetJob = data
            this.loadingGadget = false
            this.lastUpdated = new Date().toString()
            if (this.reloadableStatus.includes(data.status)){
                this.jobCompleted = false;
                } else {
                clearInterval(this.timer)
                this.jobCompleted = true;
                }
        }).catch(err => {
            this.error = this.getErrorString(err)
            this.jobCompleted  = false
        })
      }
  }
}
</script>