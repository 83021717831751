<template>
  <div>

    <!-- loader -->
    <div v-if="loading" class="loading">
        <b-spinner type="grow" label="Loading..."></b-spinner> Loading job {{ this.jobId }}...
    </div>

    <br>

    <!-- display job info as table -->
    <ObjectInfo 
      style="margin: 0 auto; max-width: 60rem; padding: 0 3rem;" 
      v-if="!loading" 
      :key="'table-' + statusHash" 
      :obj="displayJob"
    />

  <!-- Collapsible JSON Job Object -->
  <b-col>

    <!-- info row under main job description table -->
    <b-row v-if="!loading">
      
      <!-- empty col for margin -->
      <b-col></b-col>

      <!-- Show object button -->
      <b-col class="below-table">
        <b-button :key="'json-' + statusHash" v-b-toggle.job-object class="m-1">Show Job Object</b-button>
      </b-col>

      <!-- status col -->
      <b-col>

        <!-- job still running spinner -->
        <div 
          v-if="!jobCompleted"
          class="below-table"
        >
          <b-spinner 
            variant="warning" 
            label="Loading..."
          >
          </b-spinner> Job is running
        </div>

        <!-- some tasks failed result -->
        <div 
          class="below-table" 
          v-else-if="jobCompleted && someTasksFailed"
        >
          <b-icon 
            icon="exclamation-circle" 
            variant="danger"
          /> Some tasks failed
        </div>

        <!-- no tasks failed result -->
        <div 
          class="below-table" 
          v-else
        >
          <b-icon 
            icon="check-circle" 
            variant="success"/> All tasks succeeded
        </div>

      </b-col>

      <!-- empty col for margin -->
      <b-col></b-col>

    </b-row>

    <!-- collapsible job json object -->
    <b-row style="margin-top: 1.5rem;">
      <b-collapse id="job-object" style="margin: auto;">
        <b-card>
          <div 
            class="o2-json-item o2-automation-log"
          >{{ JSON.stringify(job, null, '\t') }}</div>
        </b-card>
      </b-collapse>
    </b-row>

  </b-col>

  </div>
</template>

<script>
// @ is an alias to /src
import ObjectInfo from '@/components/ObjectInfo.vue'
import mixins from '@/mixins';
export default {
  name: 'PerseusJob',
  mixins: [mixins],
  data () {
      return {
          jobId: this.$route.params.jobId,
          job: {},
          displayJob: {},
          someTasksFailed: false,
          loading: true,
          jobCompleted: false,
          statusHash: ""    // keep track of all status for vue to update
      }
  },
  components: {
    ObjectInfo
  },
  created () {
    this.timer = setInterval(this.refreshJob, 3000)
  },
  methods: {
      refreshJob () {
        this.automationApiRequest(`/perseus/jobs/${this.jobId}`).then(job =>{
            this.job = job
            this.loading = false
            this.displayJob = this.generateDisplayJob(job)
            this.updateStatusHash(job)
            if (this.job.Status === 'COMPLETED' || this.job.Status === 'FAILED'){
                clearInterval(this.timer)
                this.jobCompleted = true;
            }
        })
      },
      updateStatusHash(job) {
          if (job.Tasks) {
              this.statusHash = job.Status.concat(';').concat(Object.values(job.Tasks).map(task => task.Status).join(';'))
          }
      },
      generateDisplayJob(job) {
        
        const processingStatus = ['PROCESSING', 'RECEIVED']

        const displayedAttrs = [
          "id", "AccountId", "Action", "Creator", "CustomerShortName",
          "DateReceived", "Status", "Type"
        ]

        let displayJob = Object.fromEntries(
          Object.entries(job)
          .filter(([key]) => displayedAttrs.includes(key))
        )

        // group by which are succeeded, processing, or failed
        displayJob.TasksSucceded = [];
        displayJob.TasksProcessing = [];
        displayJob.TasksFailed = [];
        for (const [taskName, task] of Object.entries(job.Tasks)) {
          if (task.Status === 'SUCCEEDED'){
            displayJob.TasksSucceded.push(taskName)
          } else if (processingStatus.includes(task.Status)){
            displayJob.TasksProcessing.push(taskName)
          } else {
            displayJob.TasksFailed.push(taskName)
          }
        }
        
        // create readable attrs
        displayJob.TasksSucceded = displayJob.TasksSucceded.join(', ') || 'None'
        displayJob.TasksProcessing = displayJob.TasksProcessing.join(', ') || 'None'
        displayJob.TasksFailed = displayJob.TasksFailed.join(', ') || 'None'
        
        // check if some failed
        this.someTasksFailed = displayJob.TasksFailed !== 'None' || job.Status == 'FAILED'

        return displayJob
      }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<style scoped lang="scss">

.below-table {
  text-align: center;
  margin: auto;
  padding: 0.3rem; 
  font-size: 1.2rem;
  white-space: nowrap;
}

</style>