<template>
  <div>
    <b-table 
      striped hover small
      :sort-by="'key'" 
      :items="objectInfoItems" 
      :fields="objectInfoFields"
      show-empty
      responsive
      bordered
      stacked="md"
    >

      <!-- Custom cell styling -->
      <template #cell(value)="data">
          <div class="o2-log-container">
              <pre class="o2-log-text">{{ data.item.value }}</pre>
          </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  name: 'ObjectInfo',
  mixins: [mixins],
  props: ['obj'],
  data () {
    return {
      objectInfoItems: [],
      objectInfoFields: [
          {
              "key" : "key",
              "sortable" : true
          },
          {
              "key" : "value",
              "sortable" : true
          }
      ]
    }
  },
  mounted () {
  this.$nextTick(function () {
    var dotPathObj = this.dotPathObject(this.obj)
    for (var key in dotPathObj) {
        this.objectInfoItems.push({key:key, value: dotPathObj[key]})
    }
  })
  }
}
</script>
