<template>
  <div style="margin: 0 auto; max-width: 100rem; padding: 0 2rem;">

    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
      
    <!-- button for refreshing contracts-->
    <b-button 
      variant="secondary" pill
      style="margin-bottom: 1rem;"
      @click="reloadContracts"
    >
      <b-icon icon="arrow-clockwise" /> Reload contracts
    </b-button>

    <!-- button for creating new contract-->
    <b-button 
      v-if="availableConfigurationNames.length > 0"
      v-b-modal.create-contract-modal 
      variant="info" pill
      style="margin-bottom: 1rem; margin-left: 1rem;"
    >
      <b-icon icon="plus"/> Add configuration contract
    </b-button>

    <!-- autorefresh buttons -->
    <b-button 
      v-if="!autoRefresh"
      style="margin: 0 0 1rem 1rem;"
      variant="info" pill
      @click="autoRefreshOn"
    >
      <b-icon icon="arrow-clockwise" /> Auto Refresh On
    </b-button>
    <b-button 
      v-if="autoRefresh"
      style="margin: 0 0 1rem 1rem;"
      variant="secondary" pill
      @click="autoRefreshOff"
    >
      <b-icon icon="x-circle" /> Auto Refresh Off
    </b-button>

    <p v-if="availableConfigurationNames.length === 0">No additional configuration contracts available for this entity.</p>
    

    <!-- modal popup for creating new contract -->
    <b-modal 
      id="create-contract-modal"  
      size="md" 
      title="Saffron Contracts" 
      @ok="createContract"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Configuration Type"
          label-for="configuration-select"
          invalid-feedback="Selection is required"
          :state="configurationSelectState"
        >
          <b-form-select
            id="configuration-select"
            v-model="configurationSelect"
            :state="configurationSelectState"
            :options="availableConfigurationNames"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-for="parameter in configurationSelectParameters"
          :key="parameter"
          :label="parameter"
          :label-for="`${parameter}-input`"
        >
          <b-form-input
            :id="`${parameter}-input`"
            v-model="configurationFormParameters[parameter]"
            placeholder="optional..."
          >
          </b-form-input>

        </b-form-group>
        <p v-if="configurationSelect !== null">{{ configurationSelectDescription }}</p>
      </form>
    </b-modal>

    <!-- loader -->
    <div v-if="loading" class="o2-absolute-br o2-border-item" style="padding: 0.5rem;">
        <b-spinner style="width: 1.5rem; height: 1.5rem;" type="grow" label="Loading contracts..."></b-spinner> {{ loadingMessage }}
    </div>

    <b-table 
      v-if="saffronContracts.length > 0" 
      striped hover 
      :sort-by="'LastChecked'" 
      :sort-desc='true'
      ref="table"
      :key="contractsLastUpdated"
      :items="saffronContracts" 
      :fields="contractFields"
      show-empty
      responsive
      stacked="md"
    >
      <!-- template for link to resource -->
      <template #cell(ContractId)="data">
          <b-button pill variant="outline-secondary" size="sm" @click="showManageModal(data.item, data.index, $data.target)" class="mr-1">
            Manage
          </b-button>
      </template>

      <!-- template for link to resource -->
      <template #cell(LockedBy)="data">
        <router-link :to="`/saffron/jobs/${data.item.LockedBy}`">{{ data.item.LockedBy }}</router-link>
      </template>
    </b-table>

    <p v-else-if="!loading">No contracts found.</p>

    <!-- ManageModal modal -->
    <b-modal 
      :id="manageModal.id" 
      :title="manageModal.title"
      size="xl" hide-footer
      @hide="resetManageModal"
    >
      <SaffronContract :contractId="manageModal.ContractId" :hideEntityLink='true'/>
      <template #modal-title>
            <router-link :to="`/saffron/contracts/${manageModal.ContractId}`"><b-icon class="o2-grow" icon="link" /></router-link>
            {{ manageModal.title }} 
      </template>
    </b-modal>

      <!-- running job list -->
      <div v-if="runningJobs.length > 0 && this.autoRefresh" class="o2-item-pane">
        <h3>Running jobs</h3><hr>

        <div class="loading" style="margin: 2rem 0rem;">
            <b-spinner variant="warning" label="Jobs are running"></b-spinner> Jobs are running
        </div>
        <b-container fluid="xl" style="margin: 0;">
          <b-row>
            <div 
              :key="job.JobId"  
              v-for="job in runningJobs" class="o2-grid-item"
              @dblclick="navigateToJob(job)"
            >
                <span class="o2-grid-item-line">{{ job.ConfigurationType }}</span>
                <span class="o2-grid-item-line">{{ job.Action }}</span>  
                <span class="o2-grid-item-line">{{ job.Creator }}</span>  
            </div>
          </b-row>
        </b-container>
      </div>

  </div>
</template>

<script>
import SaffronContract from '@/components/SaffronContract.vue'
import mixins from '@/mixins';
export default {
  name: 'SaffronEntityContracts',
  components: {SaffronContract},
  mixins: [mixins],
  props: ['EntityId', 'EntityType'],
  data () {
    return {
      
      // whether to enable autorefresh for running jobs
      autoRefresh: false,

      // whether anything is loading
      loading: true,
      
      // what loading message to display
      loadingMessage: "Loading contracts...",

      // any error to show in banner
      error: "",

      // contracts for entity
      timer: null,

      contractsLastUpdated: 0,
      saffronContracts: [],
      contractFields: [
          {
              "key" : "ContractId",
              "sortable" : false,
              "label" : "Manage"
          },
          {
              "key" : "ConfigurationType",
              "sortable" : true
          },
          {
              "key" : "LastChecked",
              "sortable" : true
          },
          {
              "key" : "Status",
              "sortable" : true
          },
          {
              "key" : "DateCreated",
              "sortable" : true
          },
          {
              "key" : "Creator",
              "sortable" : true
          },
          {
              "key" : "LockedBy",
              "sortable" : true,
              "label" : "Current job"
          }
      ],

      // other configuration contracts for entity
      configurations: [],
      Entity: {},
      configurationSelect: null,
      configurationSelectState: null,
      configurationSelectDescription: "",
      configurationSelectParameters: [],
      configurationFormParameters: {},

      // management modal for contract
      manageModal: {
        id: 'manage-modal',
        title: '',
        ContractId: ''
      }
    }
  },
  computed: {
    runningJobs: function () {
      return this.saffronContracts
        .filter(contract => contract.LockedBy != null)
        .map(contract => {
          return {
            JobId: contract.LockedBy,
            ConfigurationType: contract.ConfigurationType
          }
        })
    },
    accountid: function(){
      return this.Entity.accountid
    },
    availableConfigurationNames: function (){
      return this.configurations
        .filter(configuration => !this.existingConfigurations.includes(configuration.Name))
        .filter(configuration => !configuration.IsArchived)
        .filter(configuration => {
          if (!configuration.EnabledAccounts) {
            return true;
            } else {
              return configuration.EnabledAccounts.includes(this.accountid)
          }}).map(item => item.Name)
    }
  },
  watch: {
    // whenever question changes, this function will run
    configurationSelect: function () {
      this.configurations.forEach(config => {
        if (config.Name === this.configurationSelect) {

          // update form with parameters (if there are any)
          if (config.Parameters){
            this.configurationSelectParameters = Object.keys(config.Parameters)
          }

          // update form description
          if (config.Description) {
            this.configurationSelectDescription = config.Description
          } else {
            this.configurationSelectDescription = "No description"
          }

        }
      })
    }
  },
  mounted () {

    // reload any contracts
    this.reloadContracts()
    this.loadEntity()

  },
  methods: {
    navigateToContract(contract) {
      this.$router.push({ path: `/saffron/contracts/${contract.ContractId}` })
      this.$router.go(1)
    },
    navigateToJob(job) {
      this.$router.push({ path: `/saffron/jobs/${job.JobId}` })
      this.$router.go(1)
    },
    reloadContracts(){

      this.loading = true
      this.loadingMessage = "Loading contracts..."

      // request contracts for entity
      this.automationApiRequest(
        '/saffron/contracts?EntityId=' + this.EntityId,
        'get'
      ).then(response => {

        this.contractsLastUpdated = new Date().toString();
        this.saffronContracts = response.Items
        this.existingConfigurations = this.saffronContracts.map(contract => contract.ConfigurationType)

        // if any are not locked, refresh to watch jobs run
        if (this.saffronContracts.every(contract => contract.LockedBy == null)){
          this.deleteTimer()
        } else if (this.timer === null && this.autoRefresh) {
          this.timer = setInterval(this.reloadContracts, 3000);
        }

        // load available configurations based on what the entity already has
        this.loadingMessage = "Loading configurations..."
        this.automationApiRequest(
          '/saffron/configurations?EntityType=' + this.EntityType,
          'get'
        ).then(response => {
          
          // get configuration names; filter out existing ones
          this.configurations = response.Items

          // indicate that configurations are no longer loading
          this.loading = false
          
        }).catch(err => {
          this.error = this.getErrorString(err)
          this.loading = false
        })

      }).catch(err => {

        // no more reqeusts if it is failing
        this.deleteTimer()
        this.error = this.getErrorString(err)
        this.loading = false
      })

    },

    loadEntity(){
      const accountTypes = ["AwsAccount", "AzureSubscription"]
      const resourceTypes = [
        "AwsEc2Instance", 
        "AzureVirutalMachine", 
        "AwsEc2Vpc", 
        "AwsEc2VpnConnection",
        "AwsRdsInstance",
        "AzureVirtualMachine",
        "AzureVirtualNetwork",
        "AzureSqlServer",
        "AzureWebApp"
        ]

      if (resourceTypes.includes(this.EntityType)){
        this.automationApiRequest(
          `/cacmdb/resources/${this.EntityId}`, 
          'get'
        ).then(response =>{
          this.Entity = response
        })
      } else if (accountTypes.includes(this.EntityType)){
        this.automationApiRequest(
          `/cacmdb/accounts/${this.EntityId}`, 
          'get'
        ).then(response =>{
          this.Entity = response
          })
      }
    },
    createContract() {

      // show loading
      this.loading = true
      this.loadingMessage = "Creating contract..."

      if (!this.configurationSelect){
        this.configurationSelectState = this.$refs.form.checkValidity();
      } else {
        this.automationApiRequest(
          `/saffron/contracts`,
          'post',
          {
            EntityId: this.EntityId,
            EntityType: this.EntityType,
            ConfigurationType: this.configurationSelect,
            Parameters: this.configurationFormParameters
          }
        ).then(newContract => {
          this.saffronContracts.push(newContract)
          this.loading = false
          this.reloadContracts()
        }).catch(err => {
          this.error = "Contract creation failed: " + err.response.data.message
          this.loading = false
        })
      }
    },
    autoRefreshOff() {
      this.autoRefresh = false;
      this.deleteTimer()
    },
    autoRefreshOn() {
      this.autoRefresh = true;
      this.reloadContracts()
    },
    deleteTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    showManageModal(item, index, button) {
      this.manageModal.title = `${item.ConfigurationType} Contract`
      this.manageModal.ContractId = item.ContractId
      this.$root.$emit('bv::show::modal', this.manageModal.id, button)
    },
    resetManageModal() {
      this.reloadContracts()
      this.manageModal.title = ''
      this.manageModal.content = ''
    }
  },
  beforeDestroy () {
    
    // clear the timer!
    clearInterval(this.timer)

  }
}
</script>
