<template>
    <div style="padding: 0 2rem;">

        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <b-tabs lazy align="center" content-class="mt-3">
            <b-tab title="Info" style="margin: 0 auto;" active>

                <!-- loader -->
                <div v-if="loadingGadget" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading build...
                </div>

                <!-- json info -->
                <ObjectInfo active v-else :obj="gadget" style="max-width: 50rem; margin:auto;" />

                <!-- Collapsible JSON Job Object -->
                <b-col>

                    <!-- info row under main job description table -->
                    <b-row v-if="!loadingGadget">
                    
                    <!-- empty col for margin -->
                    <b-col></b-col>

                    <!-- Show object button -->
                    <b-col class="below-table">
                        <b-button v-b-toggle.job-object class="m-1">Show State</b-button>
                    </b-col>

                    <!-- empty col for margin -->
                    <b-col></b-col>

                    </b-row>

                    <!-- collapsible job json object -->
                    <b-row style="margin-top: 1.5rem;">
                    <b-collapse id="job-object" style="margin: auto;">
                        <b-card>
                        <div 
                            class="o2-json-item o2-automation-log"
                        >{{ JSON.stringify(state, null, '\t') }}</div>
                        </b-card>
                    </b-collapse>
                    </b-row>

                </b-col>
            </b-tab>
            <b-tab title="Run command" style="margin: 0 auto; max-width: 50rem">

                <!-- loader -->
                <div v-if="loadingGadget" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading Gadget...
                </div>
                <div align="center">
                    
                    <p>Run a Terraform command on this Gadget build</p>
                    <b-form id="create-job-form" @submit.prevent="createJob">
                        
                         <!-- input row -->
                        <b-input v-model="userComandInput" style="margin: 1rem 0;" id="create-job" type="text" />
                        
                        <!-- input button -->
                        <b-button v-if="!isSubmitted" type="submit">Submit</b-button>

                        <!-- spinner while submitted -->
                        <div v-else>
                            <b-spinner variant="warning" label="Loading..."></b-spinner> Creating job
                        </div>

                    </b-form>

                </div>
                <!-- json info -->
            </b-tab>
            <b-tab title="Jobs">
                <!-- loader -->
                <div v-if="loadingGadget" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading Gadget job...
                </div>

                <!-- json info -->
                <b-table 
                    v-if="gadgetJobs.length !== 0 && !loading" 
                    striped hover 
                    :items="gadgetJobs" 
                    :fields="gadgetFields"
                    :sort-by="'date_created'"
                    :sort-desc="true"
                    show-empty
                    responsive
                    bordered
                    stacked="md"
                >
                    <!-- template for link to resource -->
                    <template v-slot:cell(id)="data">
                        <router-link :to="`/gadget/jobs/${data.item.id}`">{{ data.item.id }}</router-link>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
export default {
  name: 'gadget',
  mixins: [mixins],
  components: {
      ObjectInfo
  },
  data() {
    return {
        buildid: this.$route.params.buildId,
        gadget: null,
        gadgetJobs: [],
        state: null,
        userComandInput: "",
        displayGadgetJob: "",
        EntityType: "",
        isSubmitted: false,
        loadingGadget: true,
        gadgetFields: [
            {
                "key" : "status",
                "sortable" : true
            },
            {
                "key" : "id",
                "sortable" : true
            },
            {
                "key" : "command",
                "sortable" : true
            },
            {
                "key" : "date_created",
                "sortable" : true
            },
            {
                "key" : "init_result.exit_code",
                "sortable" : true
            },
            {
                "key" : "command_result.exit_code",
                "sortable" : true
            },
        ],
    }
  },
  mounted () {

    // load gadget build
    this.automationApiRequest('/gadget/builds/' + this.buildid + '?state=true','get').then(data => {
        this.gadget = data
        this.state = this.gadget.state
        delete this.gadget.state
        this.loadingGadget = false
    })

    // load jobs from this build
    this.fullAutomationApiResults(
        '/gadget/jobs',
        {
            build_id: this.buildid
        }
    ).then(data => {
        this.gadgetJobs = data.map(job => this.dotPathObject(job))
        this.loading = false
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
    })

  },
  methods: {
    createJob(){

        // set isSubmitted to true to hide the submit button and prevent double requests
        this.isSubmitted = true;

        // make request
        this.automationApiRequest(
            '/gadget/jobs', 
            'post', 
            {
                build_id: this.buildid,
                command: this.userComandInput.split(" ")
            }
        ).then(data => {
            this.displayGadgetJob = data
            this.$router.push({path:"/gadget/jobs/" + data.id})
            this.$router.go(1)
        }).catch(err => {
            this.error = this.getErrorString(err)
            this.isSubmitted = false;
        })
    },
  }
}
</script>