<template>
  <div class="container">
      <b-tabs class="main" content-class="mt-3">
      <!-- alert banner -->
        <b-tab title="Get Job" active>
          <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
          <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

          <input class="searchbar" placeholder="Place Gadget job ID..." v-model="searchInput" v-on:keyup="search">
          <h3> Get Gadget Job data </h3>
          <p> __________________________________________ </p>
          <pre> {{ gadgetJob }} </pre>
        </b-tab>
        <b-tab title="Create Build" active>
          <p>Eneter in the form below to create a gadget build</p>
          <form id="create-build-form" @submit.prevent="createBuild">
            <div>
              <p>ID</p>
              <b-form-input v-model="userIdInput" style="margin: 1rem 0;" id="create-id" type="text"></b-form-input>
            </div>
            <b-form-group>
            
              <b-row>
                  <label for="variables-input"><h5>Terraform Variables(optional)</h5></label>
                  <b-button style="height: 80%; margin-left: 1rem;" variant="success" pill size="sm" @click="form.userVarInput.push({name: '', value: ''})"><b-icon icon="plus" /></b-button>
              </b-row>

              <div v-for="(variable, ix) in form.userVarInput" :key="`variables-input-${ix}`">

                  <label :for="`variables-input-${ix}-id`"> Name
                    <b-button style="height: 80%; margin-left: 1rem;" variant="danger" pill size="sm" @click="form.userVarInput.splice(ix, 1)"><b-icon icon="trash" /></b-button>
                  </label>
                  
                <b-form-input
                  :id="`variables-input-${ix}-name`"
                  label="Variable Name"
                  placeholder="string..."
                  v-model="variable.name"
                ></b-form-input>

                <label :for="`variables-input-${ix}-value`">Value</label>
                <b-form-input
                  :id="`variables-input-${ix}-value`"
                  label="Variable Value" 
                  v-model="variable.value"
                ></b-form-input>
                
                <hr>

              </div>
            </b-form-group>
            <div>
              <p>Template</p>
              <b-form-textarea v-model="userTempInput" size="150" style="margin: 1rem 0;" id="create-temp" type="text"></b-form-textarea>
            </div>
            <!-- input button -->
            <b-button type="submit">Submit</b-button>
          </form>
        </b-tab>
        <b-tab title="Get Build" active>
          <p>Eneter in the name of the build ID that you would like to create</p>
          <form id="get-builds-form" @submit.prevent="getBuilds">
              <input v-model="userIpInput" style="margin:10px" id="get-builds" type="text">
              <button type="submit">
                  Submit
              </button>
              <label for="update-ips"> Get a build</label>
          </form>
          <pre>{{ gadgetBuild }}</pre>
        </b-tab>
      </b-tabs>
  </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'Search',
  mixins: [mixins],
  data () {
      return {
        userIdInput: "",
        form: {userVarInput: []},
        userTempInput: "",
        isSubmitted: false,
        displayGadgetCreateBuild: [],


        // error or info banners
        error: '',
        info: '',

          // value input into searchbar
          searchInput: '',
          userIpInput: '',

          gadgetJob: '',
          gadgetBuild: '',

          // list of gadgets; start null to show loader
          gadgets: [],

          // gadgets to display
          displayedgadgets: []

      }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  computed: {},
  methods: {

    createNewElement() {
      
    },
    search() {
      this.automationApiRequest(`/gadget/jobs/${this.searchInput}`, 'GET').then(response => {
          console.log(response)
          this.gadgets = response.items
          this.gadgetJob = response
          this.displayedgadgets = response.data
      }).catch(err => {
        this.error = this.getErrorString(err)
      })
    },
    getBuilds(){
      this.automationApiRequest(`/gadget/builds/${this.userIpInput}`, 'GET').then(response => {
          console.log(response)
          this.gadgets = response.items
          this.gadgetBuild = response
          this.displayedgadgets = response.data
      }).catch(err => {
        this.error = this.getErrorString(err)
      })
    },
    createBuild(){
      var variables = {}
      this.form.userVarInput.forEach(variable => {
        variables[variable.name] = variable.value
      })
      this.isSubmitted = true;
      console.log(variables)
      this.automationApiRequest(
          '/gadget/builds', 
          'post', 
          {
              id: this.userIdInput,
              variables: variables,
              template: this.userTempInput
          }
          ).then(data => {
          this.$router.push({path:"/gadget/builds/" + data.id})
          this.displayGadgetCreateBuild = data
          }).catch(err => {
            this.error = this.getErrorString(err)
            this.isSubmitted = false;
        })
    },
  }
}
</script>

<style scoped lang="scss">
.searchbar {
  width: 100%;
  padding: 10px 18px;
  font-size: 18px;
  margin-bottom: 16px;
  &:focus {
    outline: 0;
  }
}

</style>