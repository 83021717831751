<script>  
import { Line } from 'vue-chartjs'
export default {
  name: 'SaffronReportTrend',
  extends: Line,
  props: {
    dataPoints: {
      type: Array,
      default: null
    },
    y1Color: {
      default: 'rgb(50, 80, 210)'
    },
    y2Color: {
      default: 'rgb(165, 30, 50)'
    }
  },
  data () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            label: 'Percent OK',
            fill: false,
            borderColor: this.y1Color,
            tension: 0.1,
            data: [],
            yAxisID: 'y1'
          },
          {
            label: 'Error Count',
            fill: false,
            borderColor: this.y2Color,
            tension: 0.1,
            data: [],
            yAxisID: 'y2'
          }
        ]
      },
      options: {
        title: {
            display: true,
            text: 'Saffron Active Contract Trends',
            position: 'top',
            fontSize: 24
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
            xAxes: [ {
                display: true,
                type: 'time',
                time: {
                    // parser: 'YYYY-MM-DDTHH:mm',
                    // tooltipFormat: 'll HH:mm',
                    unit: 'day',
                    unitStepSize: 1,
                    displayFormats: {
                            'day': 'YYYY-MM-DD'
                        }
                    }
                }
            ],
            yAxes: [
            {
                id: 'y1',
                type: 'linear',
                display: true,
                position: 'left',
                scaleLabel: {
                  labelString: 'Percent OK',
                  display: true,
                  fontColor: this.y1Color,
                  fontSize: 18
                },

                ticks: {
                    fontColor: this.y1Color,
                    fontSize: 18
                }

            },
            {
                id: 'y2',
                type: 'linear',
                display: true,
                position: 'right',
                scaleLabel: {
                  labelString: 'Error Count',
                  display: true,
                  fontColor: this.y2Color,
                  fontSize: 18
                },

                ticks: {
                    fontColor: this.y2Color,
                    fontSize: 18
                },

                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                }
            }
            ]
        }
      }
    }
  },
  beforeMount() {
    for (const dataPoint of this.dataPoints){
        this.chartdata.labels.push(new Date(dataPoint.DateCreated))
        this.chartdata.datasets[0].data.push(dataPoint.ContractOkPercentage)
        this.chartdata.datasets[1].data.push(dataPoint.ContractErrorCount)
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>