import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@aws-amplify/ui-vue';
import { AmplifyEventBus, AmplifyPlugin } from 'aws-amplify-vue'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install BootstrapVue
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// setup amplify
Vue.use(AmplifyPlugin, AmplifyModules)
// Amplify.configure(aws_exports);

Vue.config.productionTip = false

// for configuring saml
// https://github.com/aws-amplify/amplify-js/issues/2806
// https://gist.github.com/mrichman/45389684d4c9ea40240f362cea99302f

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT,
      responseType: 'code'
    }
  },
});

AmplifyEventBus.$on('authState', info => {
  console.log(`Here is the auth event that was just emitted by an Amplify component: ${info}`)
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
