<template>
  <div class="o2-main">

    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
    
    <!-- title text -->
    <h5 class="tab-title">{{ contract.ConfigurationType || "Automation" }} Job<hr></h5> 
    <div v-if="contract.EntityType" class="o2-absolute-tl o2-border-item" style="padding: 5px;">
      <router-link :to="EntityPath"><b-icon font-scale="1" icon="arrow-left-circle" /> {{ contract.EntityType }}</router-link>
    </div>
    
    <!-- job is running loader -->
    <div v-if="jobIsRunning && !loadingJob" class="o2-absolute-br o2-border-item" style="padding: 0.5rem;">
        <b-spinner style="width: 1.5rem; height: 1.5rem;" type="grow" label="Job is running..."></b-spinner> Job is running...
    </div>

    <!-- Tabs: Info, Logs -->
    <b-tabs lazy align="center" content-class="mt-3">
        <b-tab title="Info" active>
            
            <!-- button for cancelling job -->
            <b-button 
              v-if="jobIsRunning"
              v-b-modal.stop-job-modal
              variant="danger" pill
              style="margin-bottom: 1rem; margin-left: 1rem;"
            >
              <b-icon icon="x-circle"/> Stop job
            </b-button>

            <!-- modal popup for cancelling job -->
            <b-modal 
              id="stop-job-modal"  
              size="sm" 
              title="Stop Saffron Job?"
              ok-title = "Stop"
              ok-variant="danger"
              @ok="stopJob"
            >
            </b-modal>

            <!-- job info table -->
            <div v-if="loadingJob" class="loading">
                <b-spinner type="grow" label="Loading job..."></b-spinner> Loading job...
            </div>
            <ObjectInfo v-else-if="job" :obj="job" :key="job.Status" />
            <p v-else>Not found.</p>

        </b-tab>

        <b-tab title="Contract">
          <!-- contract info table -->
          <ObjectInfo :obj="contract" />
        </b-tab>
        
        <!-- Logs tab -->
        <b-tab title="Logs">
            <div v-if="loadingLogs" class="loading">
                <b-spinner type="grow" label="Loading logs..."></b-spinner> Loading logs...
            </div>
            <b-table 
                v-else-if="logs.length > 0" 
                striped hover sort-by="DateCreated"
                :sort-desc="true"
                :items="logs" 
                :fields="logFields"
                show-empty
                responsive
                stacked="md"
            >
                <!-- Custom cell styling -->
                <template #cell(Message)="data">
                    <div class="o2-log-container">
                        <pre class="o2-json-item o2-log-text">{{ data.item.Message }}</pre>
                    </div>
                </template>
            </b-table>
            <p v-else>No logs found.</p>
        </b-tab>

    </b-tabs>

  </div>
    
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
export default {
  name: 'SaffronJob',
  mixins: [mixins],
  components: {
      ObjectInfo
  },
  computed: {
    EntityPath: function (){
      if (['AwsAccount', 'AzureSubscription'].includes(this.contract.EntityType)){
        return `/accounts/${this.contract.EntityId}`
      } else {
        return `/resources/${this.contract.EntityId}`
      }
    }
  },
  data () {
    return {

      // error message banner
      error: "",

      // job data
      jobId: this.$route.params.jobId,
      job: {},
      jobIsRunning: false,
      loadingJob: true,

      // refresh a running job
      timer: null,

      // contract data
      loadingContract: true,
      contract: {},

      // job configuration type
      configurationType: null,
      reloadableStatus: ['InProgress', 'New', 'RetryWait'],

      // log data
      loadingLogs: true,
      logs: [],
      logFields: [
        {
            "key" : "DateCreated",
            "sortable" : true
        },
        {
            "key" : "Level",
            "sortable" : true
        },
        {
            "key" : "Type",
            "sortable" : true
        },
        {
            "key" : "Message",
            "sortable" : true
        }
      ]
    }
  },
  mounted () {

    // refresh job status
    this.refreshJob()
    this.timer = setInterval(this.refreshAll, 3000);

    // get logs
    this.refreshLogs()

  },
  methods: {
    refreshAll() {
      this.refreshLogs()
      this.refreshJob()
    },
    refreshLogs(){

      // request logs
      this.automationApiRequest(
        '/saffron/logs?JobId=' + this.jobId,
        'get'
      ).then(response => {
        this.logs = response.Items
        this.loadingLogs = false
      }).catch(err => {
        this.error = this.getErrorString(err)
      })

    },
    stopJob() {
      
      this.automationApiRequest(
        `/saffron/jobs/${this.jobId}/cancel`,
        'post'
      ).then(
        () => console.log('Job cancelled')
      ).catch(err => {
        this.error = this.getErrorString(err)
      })

    },
    loadContract() {

      this.loadingContract = true
      // request job
      this.automationApiRequest(
        '/saffron/contracts/' + this.job.ContractId,
        'get'
      ).then(contract => {
        this.contract = contract
        this.loadingContract = false
      }).catch(err => {
        this.error = this.getErrorString(err)
        this.loadingContract = false
      }) 
    },
    refreshJob(){

      // request job
      this.automationApiRequest(
        '/saffron/jobs/' + this.jobId,
        'get'
      ).then(job => {
        this.job = job
        this.loadingJob = false
        this.loadContract()
        if (!this.reloadableStatus.includes(this.job.Status)){
          this.jobIsRunning = false;
          clearInterval(this.timer)
        } else {
          this.jobIsRunning = true;
        }
      }).catch(err => {
        this.error = this.getErrorString(err)
        this.jobIsRunning = false
        clearInterval(this.timer)
      }) 

    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
.tab-title {
    text-align: left;
    max-width: 30rem;
    margin: 1rem 0 0 0;
}


</style>