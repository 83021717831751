<template>
  <div class="main">

    <!-- alert banner -->
    <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

    <b-form @submit="onSubmit" v-if="!tagCreatedSuccess">
      <label for="alias-input"><h5>Tag alias</h5></label>
      <b-form-group
        description="The tag value; e.g. ConnectriaManaged: myAlias"
      >
        <b-form-input
          id="alias-input"
          v-model="form.alias"
          placeholder="myAlias..."
          required
        ></b-form-input>
      </b-form-group>

      <label for="resource-types"><h5>Resources types</h5></label>
      <b-form-group id="resource-types" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="form.selectedResources"
          id="radiobuttons-3"
          :aria-describedby="ariaDescribedby"
        >
          <div class="o2-checkbox-grid">
            <b-form-radio 
              v-for="resource in resourceTypes"
              :key="resource"
              :value="resource">{{ resource }}
            </b-form-radio>
          </div> 
        </b-form-radio-group>
      </b-form-group>
      
      <label for="managed-services"><h5>Services</h5></label>
      <b-form-group id="managed-services" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.selectedServices"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <div class="o2-checkbox-grid">
            <b-form-checkbox 
              v-for="service in services"
              :key="service"
              :value="service">{{ service }}
            </b-form-checkbox>
          </div> 
        </b-form-checkbox-group>
      </b-form-group>

      <div style="float:right; margin: 0 1rem 1rem 0;">
          <!-- checkall buttons -->
          <b-button pill variant="info" v-if="form.selectedServices.length < services.length" @click="selectAllServices">all <b-icon icon="check2-all" /></b-button>
          <b-button pill v-else @click="selectNoServices">none <b-icon icon="x-circle" /></b-button>
      </div>
      <br>


      <b-form-group 
        v-if="form.selectedServices.includes('cpm')" 
        label-for="cpm-policy-input"
      >
      
        <b-row>
            <label for="cpm-policy-input"><h5>CPM Policy Configurations (optional)</h5></label>
            <b-button style="height: 80%; margin-left: 1rem;" variant="success" pill size="sm" @click="form.cpmPolicies.push({ami_backup_option: 'S'})"><b-icon icon="plus" /></b-button>
        </b-row>

        <div v-for="(policy, ix) in form.cpmPolicies" :key="`cpm-policy-input-${ix}`">

            <label :for="`cpm-policy-input-${ix}-id`">Policy ID
              <b-button style="height: 80%; margin-left: 1rem;" variant="danger" pill size="sm" @click="form.cpmPolicies.splice(ix, 1)"><b-icon icon="trash" /></b-button>
            </label>
            
          <b-form-input
            :id="`cpm-policy-input-${ix}-id`"
            label="Policy ID"
            placeholder="number..."
            type="number"
            v-model="policy.policyid"
          ></b-form-input>

          <label :for="`cpm-policy-input-${ix}-ami_backup_option`">AMI backup strategy</label>
          <b-form-select
            :id="`cpm-policy-input-${ix}-ami_backup_option`"
            label="AMI backup strategy" 
            :options="amiBackupOptions"
            v-model="policy.ami_backup_option"
          ></b-form-select>
          
          <hr>

        </div>
      </b-form-group>

      <b-form-group 
        v-if="form.selectedServices.includes('solarwinds')" 
        label="Solarwinds Environment" 
        label-for="sw-env-input"
        description="[Optional] Solarwinds Environment to use when configuring Orion node"
      >
        <b-form-input
          id="sw-env-input"
          placeholder="optional..."
          v-model="form.solarwindsEnv"
        ></b-form-input>
      </b-form-group>
      <b-form-group 
        v-if="form.selectedServices.includes('solarwinds')" 
        label="Solarwinds Application" 
        label-for="sw-app-input"
        description="[Optional] Solarwinds Application to use when configuring Orion node"
      >
        <b-form-input
          id="sw-app-input"
          placeholder="optional..."
          v-model="form.solarwindsApp"
        ></b-form-input>
      </b-form-group>
      <b-form-group 
        v-if="form.selectedServices.includes('solarwinds')" 
        label="Solarwinds Suffix" 
        label-for="sw-suffix-input"
        description="[Optional] Solarwinds suffix to use when configuring Orion node"
      >
        <b-form-input
          id="sw-suffix-input"
          placeholder="optional..."
          v-model="form.solarwindsSuffix"
        ></b-form-input>
      </b-form-group>

      <label for="custom-service-group"><h5>Custom services</h5></label>
      <b-form-group id="custom-service-group">

        <div v-if="customServicesForResource.length===0">No enabled custom services for this resource type.</div>
        <div v-for="service in customServicesForResource" :key="service.Name">
          <label :for="`custom-svc-input-${service.Name}`">{{ service.Name }} </label>
          <b-form-select
            :id="`custom-svc-input-${service.Name}`"
            :label="service.Name"
            :options="Object.keys(service.ValidActions).concat([notRequestedStr,])"
            v-model="service.selectedTagAction"
          ></b-form-select>
        </div>
      </b-form-group>
      
      <br>
      
      <div>
        <b-button v-if="!isSubmitted" pill type="submit" variant="info">Create</b-button>
        <div v-else class="loading">
          <b-spinner type="grow" label="Loading..."></b-spinner> Creating tag...
        </div>
      </div>
    </b-form>
    <p v-else><b-icon icon="check-square" variant="success"></b-icon> Successfully created <code>{{ this.form.alias }}</code> tag for account <code>{{ this.accountId }}</code></p>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  mixins: [mixins],
  props: {
    accountId: String,
  },
  data() {
    return {
      info: "",
      error: "",
      isSubmitted: false,
      tagCreatedSuccess: false,
      form: {
        cpmPolicies: [],
        solarwindsEnv: '',
        solarwindsApp: '',
        solarwindsSuffix: '',
        selectedServices: [],
        selectedResources: ['ec2']
      },
 
      resourceTypes: [
        'aws-ec2',
        'aws-rds',
        "aws-vpn-connection",
        "azure-vm"
      ],

      customServices: {
        'AwsEc2Instance': [],
        'AzureVirtualMachine': []
      },

      // what actions should be applied for requested Saffron custom services?
      customServiceActions: {},
      notRequestedStr: 'Not requested',

      amiBackupOptions:[
        { value: 'S', text: 'Single AMI, then snapshots' },
        { value: 'N', text: 'Only snapshots' },
        { value: 'O', text: 'Only AMIs' }
      ]
    };
  },
  computed: {
    // a computed getter
    services: function () {
        if (this.form.selectedResources.includes('aws-ec2')){
          return [          
            'cpm',
            'cloudwatch-agent',
            'trend',
            'devo',
            'solarwinds',
            'bigfix',
            'osconfig',
            'bluematador',
            'system-manager',
            'rapid7',
            'sumo'
          ]
        } else if (this.form.selectedResources.includes('aws-rds')) { 
            return ['cpm']
        } else if (this.form.selectedResources.includes('aws-vpn-connection')) {
            return ['cloudwatch-alarm']
        } else if (this.form.selectedResources.includes('azure-vm')) {
          return [          
            'trend',
            'devo',
            'solarwinds',
            'bigfix',
            'osconfig',
            'bluematador',
            'rapid7',
            'sumo'
          ]
        } else {
          return []
        }
    },
    customServicesForResource: function() {
      // what custom services are available for this resource type
      if (this.resourcetype === 'aws-ec2'){
        return this.customServices['AwsEc2Instance']
      } else if (this.resourcetype === 'azure-vm'){
        return this.customServices['AzureVirtualMachine']
      } else {
        return []
      }
    },
    resourcetype: function () {
      if (this.form.selectedResources.includes('aws-ec2')){
          return 'aws-ec2'          
          
      }
      else if (this.form.selectedResources.includes('aws-rds')) { 
            return 'aws-rds'
      }
      else if (this.form.selectedResources.includes('aws-vpn-connection')) {
            return 'aws-vpn-connection'
      }
      else { 
            return 'azure-vm'
      }
    }
  },
  mounted () {

    // reload any relevant custom services
    this.loadCustomServices()

  },
  methods: {
    onSubmit(event) {

      event.preventDefault();
      
      if (!this.cpmPoliciesUnique()){
        this.error = 'Duplicate CPM policy IDs have been specified'
        console.log(this.error)
        return null;
      }

      this.isSubmitted = true;

      let dataBody = {
        alias: this.form.alias,
        accountid: this.accountId,
        services: {},
        customservices: {},
        resourcetype: this.resourcetype
      };
      
      // add each selected service
      this.form.selectedServices.forEach(service => {

        // only include services relevant to current selected resource type
        if (this.services.includes(service)){
          dataBody.services[service] = {
            automated: true,
            requested: true
          }
        }
      });

      if (this.form.solarwindsEnv && this.form.selectedServices.includes('solarwinds')) {
        dataBody.services.solarwinds.environment = this.form.solarwindsEnv
      }
      if (this.form.solarwindsApp && this.form.selectedServices.includes('solarwinds')) {
        dataBody.services.solarwinds.application = this.form.solarwindsApp
      }
      if (this.form.solarwindsSuffix && this.form.selectedServices.includes('solarwinds')) {
        dataBody.services.solarwinds.suffix = this.form.solarwindsSuffix
      }
      if (this.form.cpmPolicies && this.form.selectedServices.includes('cpm')) {
        dataBody.services.cpm.policies = this.form.cpmPolicies;
      }

      // add custom services to the request
      this.customServicesForResource.forEach(svc => {
        if (svc.selectedTagAction !== this.notRequestedStr){
          dataBody.customservices[svc.Name] = {
            Action: svc.selectedTagAction
          }
        } 
      })

      this.error = ""
      this.automationApiRequest(
        '/cacmdb/tags',
        'post',
        dataBody
      ).then(() => {
        this.tagCreatedSuccess = true;
      }).catch(err => {
        this.isSubmitted = false
        this.error = this.getErrorString(err)
      })
    },
    cpmPoliciesUnique() {
      let policyIds = [];
      for (const policy of this.form.cpmPolicies){
        if (policyIds.includes(policy.policyid)){
          return false;
        } else {
          policyIds.push(policy.policyid)
        }
      }
      return true;
    },
    selectAllServices() {
      this.form.selectedServices = this.services
    },
    selectNoServices() {
      this.form.selectedServices = [];
    },
    async loadCustomServices() {
      this.fullAutomationApiResults('/saffron/configurations').then(configurations => {
        configurations.forEach(configuration => {
          let accounts = configuration.EnabledAccounts || [];
          if (accounts.includes(this.accountId) && configuration.IsCustom){

            // append to existing services for entity type
            configuration.selectedTagAction = this.notRequestedStr
            this.customServices[configuration.EntityType].push(configuration)
            
          }
        })

      })
    }
  }
};
</script>
