<template>
  <div style="margin-left: 2vw; margin-right: 2vw;">

        <b-tabs lazy align="center" content-class="mt-3">
            <b-tab title="AWS Saffron Contracts" active>
                <AwsSaffronReport />
            </b-tab>
            <b-tab title="AWS Saffron Trend">
              <AwsSaffronTrend />
            </b-tab>
            <b-tab title="Blue Matador Billing">
              <BlueMatadorBillingReport/>
            </b-tab>
        </b-tabs>

  </div>
</template>

<script>
// @ is an alias to /src
import BlueMatadorBillingReport from '@/components/BlueMatadorBillingReport.vue'
import AwsSaffronReport from '@/components/AwsSaffronReport.vue'
import AwsSaffronTrend from '@/components/AwsSaffronTrend.vue'

export default {
  name: 'Reports',
  components: {
    BlueMatadorBillingReport,
    AwsSaffronReport,
    AwsSaffronTrend
  }
}
</script>