import Vue from 'vue'
import VueRouter from 'vue-router'
import Accounts from '@/views/Accounts.vue'
import Account from '@/views/Account.vue'
import Resource from '@/views/Resource.vue'
import Tag from '@/views/Tag.vue'
import Saffron from '@/views/Saffron.vue'
import SaffronContract from '@/components/SaffronContract.vue'
import SaffronJob from '@/views/SaffronJob.vue'
import Automation from '@/views/Automation.vue'
import PerseusJob from '@/views/PerseusJob.vue'
import Search from '@/views/Search.vue'
import Peppermint from '@/views/Peppermint.vue'
import Onboard from '@/views/Onboard.vue'
import Reports from '@/views/Reports.vue'
import Gadget from '@/views/Gadget.vue'
import GadgetBuild from '@/views/GadgetBuild.vue'
import GadgetJob from '@/views/GadgetJob.vue'
import Claude from '@/views/Claude.vue'
import SsmSession from '@/views/SsmSession.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Search',
    component: Search
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/accounts/:accountid',
    name: 'Account',
    component: Account
  },
  {
    path: '/perseus/jobs/:jobId',
    name: 'PerseusJob',
    component: PerseusJob
  },
  {
    path: '/resources/:resourceid',
    name: 'Resource',
    component: Resource
  },
  {
    path: '/tags/:tagId',
    name: 'Tag',
    component: Tag
  },
  {
    path: '/saffron',
    name: 'Saffron',
    component: Saffron
  },
  {
    path: '/saffron/contracts/:contractId',
    name: 'SaffronContract',
    component: SaffronContract,
    props: route => ({ contractId: route.params.contractId })
  },
  {
    path: '/saffron/jobs/:jobId',
    name: 'SaffronJob',
    component: SaffronJob
  },
  {
    path: '/automations/:automationid',
    name: 'Automation',
    component: Automation
  },
  {
    path: '/peppermint',
    name: 'Peppermint',
    component: Peppermint
  },
  {
    path: '/claude',
    name: 'ClAuDE',
    component: Claude
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/gadget',
    name: 'Gadget',
    component: Gadget
  },
  {
    path: '/gadget/builds/:buildId',
    name: 'Gadget Build',
    component: GadgetBuild
  },
  {
    path: '/gadget/jobs/:jobId',
    name: 'Gadget Job',
    component: GadgetJob
  },
  {
    path: '/ssm-session/:resourceId',
    name: 'SSM Session',
    component: SsmSession
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
